// @ts-nocheck
// TODO: we need to update code and dependencies to use the new google maps api and remove the @ts-nocheck

import { GoogleMapsLocale } from "../hooks/useGoogleMapsAutocompleteSuggestions"

import { trackUserInteraction } from "./tracking/dataLayer"

export type CasavoAddress = {
  city: string
  country: string
  fullAddress: string
  lat: number
  lon: number
  province: string
  provinceInitials: string
  street: string
  streetNumber: string
  zipCode: string
}

export type GoogleMapsAddress = {
  coordinates: google.maps.LatLngLiteral
  geoCodeResult: google.maps.GeocoderResult
}

const isDevOrStaging = () =>
  process.env.NODE_ENV === "development" ||
  process.env.NEXT_PUBLIC_APP_ENV === "staging" ||
  process.env.NEXT_PUBLIC_APP_ENV === "preview"

// Define a discriminated union type for geocode parameters
type GeocodeParams = { address: string } | { placeId: string };

/**
 * Use Google Maps Geocode on an address string or a place id, returning location data.
 *
 * @param param the address string or Google Maps the placeId to geocode as object
 *
 * @example geocode({address: 'Corso Italia 1, Milano, Città Metropolitana di Milano, Italia'})
 * @example geocode({placeId: 'ChIJZb0ShKnGhkcRc2PaJ2mKf1o'})
 */
function geocode(param: GeocodeParams): Promise<google.maps.GeocoderResult[]> {
  const geocoder = new window.google.maps.Geocoder()
  const OK = window.google.maps.GeocoderStatus.OK

  return new Promise((resolve, reject) => {
    geocoder.geocode(param, (results: any, status: any) => {
      if (status !== OK) {
        reject(status)
      }
      resolve(results)
    })
  })
}

function getLatLng(result: google.maps.GeocoderResult): Promise<google.maps.LatLngLiteral> {
  return new Promise((resolve, reject) => {
    try {
      const latLng = {
        lat: result.geometry.location.lat(),
        lng: result.geometry.location.lng(),
      }
      resolve(latLng)
    } catch (e) {
      reject(e)
    }
  })
}

function mapGoogleMapsToDorisAddess({ coordinates, geoCodeResult }: GoogleMapsAddress): CasavoAddress {
  const extract = (key: string) =>
    geoCodeResult.address_components.find((x) => {
      return x.types.includes(key)
    })?.short_name || ""

  const city = extract("locality") || extract("political")

  const provinceComponent = geoCodeResult.address_components.find((x) => {
    return x.types.includes("administrative_area_level_2")
  })

  return {
    city: city,
    country: extract("country"),
    fullAddress: geoCodeResult.formatted_address,
    lat: coordinates.lat,
    lon: coordinates.lng,
    province: provinceComponent.long_name,
    provinceInitials: provinceComponent.short_name,
    street: extract("route"),
    streetNumber: extract("street_number"),
    zipCode: extract("postal_code"),
  }
}

/**
 * Geocode a location based on a given Place ID and get the best match.
 *
 * see https://developers.google.com/maps/documentation/javascript/geocoding#GeocodingRequests
 *
 * @param {string} placeId - The Place ID representing a specific place to be geocoded.
 * @return {Promise<CasavoAddress>} A promise that resolves to a CasavoAddress object containing the geocoded address information.
 *
 * @example geocodeByPlaceId('EicxMyBNYXJrZXQgU3QsIFdpbG1pbmd0b24sIE5DIDI4NDAxLCBVU0E')
 */
export function geocodeByPlaceId(placeId: string): Promise<any> {
  return new Promise((resolve) => {
    if (isDevOrStaging()) {
      console.info("[GEOCODING PLACE ID] ", placeId)
    }
    geocode({ placeId: placeId }).then((results) => {
      const bestMatch = results[0]
      getLatLng(bestMatch).then((coordinates) => {
        const rawData = {
          coordinates,
          geoCodeResult: bestMatch,
        }
        if (isDevOrStaging()) {
          console.info("[GEOCODE BEST MATCH] ", JSON.stringify(rawData))
        }
        const result = mapGoogleMapsToDorisAddess(rawData)
        resolve({ rawData, result })
      })
    })
  })
}

/**
 * Get the best match location by geocoding with Google Maps a full address string
 *
 *
 * @param {string} address - The address string to geocode.
 * @return {Promise<any>} A promise that resolves to an object containing the geocoding result and mapped address.
 *
 * @example geocodeByAddress('Via del Corso 1, Firenze, Città metropolitana di Firenze, Italia')
 */
export function geocodeByAddress(address: string): Promise<any> {
  return new Promise((resolve) => {
    if (isDevOrStaging()) {
      console.info("[GEOCODING ADDRESS] " + address)
    }
    geocode({ address: address }).then((results) => {
      const bestMatch = results[0]
      getLatLng(bestMatch).then((coordinates) => {
        const rawData = {
          coordinates,
          geoCodeResult: bestMatch,
        }
        if (isDevOrStaging()) {
          console.info("[GEOCODE BEST MATCH] ", JSON.stringify(rawData))
        }
        const result = mapGoogleMapsToDorisAddess(rawData)
        resolve({ rawData, result })
      })
    })
  })
}

export function splitStreetAndCity(description: string): { city: string; street: string } {
  const components = description.split(", ")
  const cityComponents = components.splice(-3, 3)

  return { city: cityComponents.join(", "), street: components.join(", ") }
}

export const fromAddressToQueryParams = (address: CasavoAddress) => ({
  city: address.city,
  country: address.country,
  lat: String(address.lat),
  location: address.fullAddress,
  lon: String(address.lon),
  province: address.province,
  provinceInitials: address.provinceInitials,
  street: address.street,
  streetNumber: address.streetNumber,
  zipCode: address.zipCode,
})

export const fromAddressToParametrizedQueryString = (address: CasavoAddress): string => {
  const searchParams = new URLSearchParams(fromAddressToQueryParams(address))
  return searchParams.toString().replace(/\+/g, "%20")
}

export const googleMapsLocaleFromLang = (lang: Locale): GoogleMapsLocale => {
  let googleMapsLocale: GoogleMapsLocale = undefined

  switch (lang) {
    case "en":
      googleMapsLocale = undefined
      break
    case "it":
      googleMapsLocale = "it-IT"
      break
    case "fr":
      googleMapsLocale = "fr-FR"
      break
  }

  return googleMapsLocale
}

export const onSubmit = (websiteUrl: URL) => (gmapsAddress: CasavoAddress) => {
  if (gmapsAddress.fullAddress) {
    trackUserInteraction("Address", "Insert")
    window.location.href = [websiteUrl, "?", fromAddressToParametrizedQueryString(gmapsAddress)].join("")
  }
}
