import { useRouter } from "next/router"
import { useEffect } from "react"

const MARKETING_CAMPAIGN_PARAMS = "marketing_campaign_params"
export const useSaveMarketingCampaignParams = () => {
  const router = useRouter()
  useEffect(() => {
    if (typeof window === "undefined") {
      // window is not accessible in SSR
      return
    }

    const queryParamsToBeForwarded: Record<string, string> = getMarketingCampaignQueryParams()

    for (const queryParam in queryParamsMap) {
      const value = router.query[queryParam]
      if (value !== undefined) {
        queryParamsToBeForwarded[queryParamsMap[queryParam]] = value as string
      }
    }

    window.sessionStorage.setItem(MARKETING_CAMPAIGN_PARAMS, JSON.stringify(queryParamsToBeForwarded))

    // eslint-disable-next-line react-hooks/exhaustive-deps
    // query param value could be undefined on first render: https://github.com/vercel/next.js/discussions/11484
  }, [router.query])
}
const queryParamsMap: Record<string, string> = {
  at_gd: "at_gd",
  at_geo: "at_geo",
  dicbo: "ob_click_id",
}

const omitNullValues = (object: Record<string, string | null>) =>
  Object.fromEntries(Object.entries(object).filter(([_, val]) => val !== null)) as Record<string, string>

export const getMarketingCampaignQueryParams = () => {
  const params = window.sessionStorage.getItem(MARKETING_CAMPAIGN_PARAMS)

  return params ? omitNullValues(JSON.parse(params)) : {}
}
