import { Body } from "@casavo/habitat"

import { sprinkles } from "@app/shared/design-lib/style-theme/sprinkles.css"
import { CommonAutocomplete } from "@app/shared/types/website-pages"

import { Alert } from "../../icons/Alert"

import { toastErrorGoogleMaps } from "./styles.css"

type ToastErrorGoogleMapsProps = {
  description: CommonAutocomplete["error_description"]
  title: CommonAutocomplete["error_title"]
}

export const ToastErrorGoogleMaps: React.FC<ToastErrorGoogleMapsProps> = ({ description, title }) => {
  return (
    <div className={toastErrorGoogleMaps}>
      <Alert className={sprinkles({ flexShrink: 0, marginRight: "s", marginTop: "xs" })} />
      <div>
        <div className={sprinkles({ marginBottom: "xs" })}>
          <Body noMargin strong size="m">
            {title}
          </Body>
        </div>
        <Body noMargin size="s">
          {description}
        </Body>
      </div>
    </div>
  )
}
