/* In order to avoid fetcing google Places (paid) api for terms that are not related to a single address we are using this mechanism:
   
   When a query starts with a blacklisted term(or part of it) and/or with a city name plus a whitespace pus a blacklisted term (or part of it) 
    queries that are less than 3 char dont trigger a request
*/

const BLACKLISTED_WORDS = ["viale", "piazzale", "corso"] // substrings such as "piazza" and "via" are matched
const BLACKLISTED_CITIES = [
  "roma",
  "milano",
  "torino",
  "verona",
  "bologna",
  "napoli",
  "catania",
  "naples",
  "palermo",
  "genova",
  "firenze",
  "bari",
  "venezia",
  "madrid",
  "barcellona",
  "valencia",
]
const MIN_QUERY_LEN = 3

export function shouldFetchSuggestions(
  searchQuery: string,
  blacklistedCities = BLACKLISTED_CITIES,
  blacklistedWords = BLACKLISTED_WORDS
): boolean {
  function stringArrayToRegex(ss: string[]) {
    return ss.map((s) => createStringPartialRegex(s, MIN_QUERY_LEN)).join("|")
  }

  const blacklistedCitiesRegex =
    blacklistedCities.length > 0 ? `((${stringArrayToRegex(blacklistedCities)})\\s?)?` : ""
  const blacklistedWordsRegex =
    blacklistedWords.length > 0 ? `((${stringArrayToRegex(blacklistedWords)})\\s?)?` : ""
  const reg = new RegExp("^" + blacklistedCitiesRegex + blacklistedWordsRegex)

  return searchQuery.toLowerCase().replace(reg, "").length >= MIN_QUERY_LEN
}

export function createStringPartialRegex(s: string, firstOptionalLetterIndex: number) {
  return s.slice(0, firstOptionalLetterIndex) + s.slice(firstOptionalLetterIndex).split("").join("?") + "?"
}
