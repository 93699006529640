// @ts-nocheck
// TODO: we need to update code and dependencies to use the new google maps api and remove the @ts-nocheck

import { useEffect, useRef, useState } from "react"

import { useHTMLDialogElementSupported } from "@shared/hooks/useHTMLDialogElementSupported.ts"

import useGoogleMapsChecker from "../useGoogleMapsChecker.ts"

import { shouldFetchSuggestions } from "./should-fetch-suggestions"

const useGoogleMapsAutocompleteSuggestions = (
  countries: Locale | Locale[],
  googleMapsLocale: GoogleMapsLocale,
  types = ["address"]
) => {
  if (!process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY) {
    throw new Error(`[useGoogleMapsAutocompleteSuggestions] Missing Google Maps Api Key`)
  }

  const autoComplete = useRef<google.maps.places.AutocompleteService>()
  const [suggestions, setSuggestions] = useState<google.maps.places.AutocompletePrediction[]>([])

  // Instantiates the automplete service ONCE
  const createAutocompleteService = () => {
    if (typeof autoComplete.current === "undefined" && typeof window?.google?.maps !== "undefined")
      autoComplete.current = new window.google.maps.places.AutocompleteService()
  }

  const hasGoogleMaps = useGoogleMapsChecker()
  const { isHTMLDialogElementSupported } = useHTMLDialogElementSupported()

  useEffect(() => {
    if (hasGoogleMaps && isHTMLDialogElementSupported) {
      createAutocompleteService()
    }
  }, [hasGoogleMaps, isHTMLDialogElementSupported])

  // Prediction function associated with the autocomplete service
  const predictSuggestions = (query: string) =>
    new Promise<void>((resolve) => {
      if (
        typeof autoComplete.current !== "undefined" &&
        query.trim() !== "" &&
        shouldFetchSuggestions(query)
      ) {
        autoComplete.current.getPlacePredictions(
          { componentRestrictions: { country: countries }, input: query, types },
          (predictions, status) => {
            if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
              setSuggestions([])
            } else {
              setSuggestions(predictions)
            }
          }
        )
      } else {
        setSuggestions([])
      }
      resolve()
    })

  return { predictSuggestions, suggestions }
}

export default useGoogleMapsAutocompleteSuggestions
