import { useEffect, useState } from "react"

export default function useGoogleMapsChecker() {
  const [state, setState] = useState(false)

  useEffect(() => {
    if (window?.google?.maps) {
      setState(Boolean(window?.google?.maps))
      return
    }

    const intervalID = setInterval(() => {
      if (window?.google?.maps) {
        clearInterval(intervalID)
        setState(Boolean(window?.google?.maps))
      }
    }, 50)

    return () => clearInterval(intervalID)
  }, [])

  return state
}
