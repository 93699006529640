import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fshared%2Fcomponents%2FAddressAutocomplete%2Fstyles.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA61WXW%2BbMBR976%2B4UjWplUYUIEDqvkxRV2m%2FYI%2BTg53gFWxkTEM69b9PtiFgPtJqWvKQGN9z7ofPvWb1y4%2F9s38ia%2FhzA1CKiikmOAJJc6zYK328AXjzGCe0QeCvH2%2Feb1Ydxv8sBuDEiMr0Yv3FoQgMBWFVmeMzAi44dfbDD%2FY3Zh%2Fn7Mg9pmhRIaiUpCrNHoe4Q04b%2FUD%2FeoRJmtqIU5HXBdc7R1wi8OPSmBVYHhlHsAZcK%2BE4jK7kvJRkbDA5PSgEW%2BuhJ8D7SuS1MgRKlAg22mKATgx6j9OXoxQ1J14qciER3D6bj8bthSRUIvDLBiqRMwK3u3AX7oJ%2B05OYsLpq6W0lEPjmr%2BDKO%2BCC5WcEP7ii8vK0Ym8Ugd8GnVF2zBSCqOUoMSGMHxEEQdnozDrypUIk6CDSuvqnhPwn%2F8nf6U1Rq5xxOqOGZFVl4vQTS8740XppiYKe6HsUb6LYwW2nKkppV4iJiIxUNiOl2EXjdTUyD0SJU6bO3eqVykMuTggyRgg1slMS804JlgtWYfW1Q9pFz6vXbuSTjLuINmUzjSqkhROX75A9XCtDf15oeBjz6pqe6KSKXUhxC%2FldV4odzl4quKJcDV1fdOY%2FlA2EgQUo2ijPBDu0HRZ0LDFY%2BZWRZ8YU9aoSp0ZEJ4lLtw4Ip7qnl3QaBVEQjUqHMn28S4gkTMIkcBD4%2F7Z17Ba%2BPyA7dta9WD0zZbZOC9tnwdXZNCi35rzMq%2BiDpt%2BbRNNaVjqyUjBnwkzmTn%2FYumftRB6yXS%2F0c6S%2FDiJ1r5B9LtKXi%2FtTq8K9yMmgRHuhlCims5jYXCZFXmBvx%2BeYxQrrkqgenDbZGWVLHZ%2BDPrj5MK6HoXdx3PVV5%2FVbQQnDUKWSUg6YE7grGPcupxVsyubeUI7u9cuJhvHaxvU%2BNApao4VxMdPx7eR8xfLO807bLQ%2BTkN3bHT2kWnfJunW3dM06YYRzYQxeAWbjWH4N%2BGSM8yXZtLGMXldGVklrNR6Ao8vEwTy0mL45Ej0Jk5kgcGtqejMOOov3v3XQkDLqCQAA%22%7D"
export var autoComplete = '_161y1wd0';
export var autoCompleteStreetNumber = '_161y1wd1';
export var formButton = '_161y1wd9';
export var formDesktop = {abTestVersionControl:'_161y1wd2',abTestVersionB:'_161y1wd3'};
export var formInput = '_161y1wd7';
export var formInputWrapper = '_161y1wd5';
export var formLabel = '_161y1wd6';
export var formMobile = '_161y1wd4';
export var formWarning = '_161y1wd8';
export var suggestionsGoogleLogo = '_161y1wdf';
export var suggestionsGoogleLogoWrapper = '_161y1wde';
export var suggestionsItem = '_161y1wdb';
export var suggestionsItemCity = '_161y1wdd';
export var suggestionsItemStreet = '_161y1wdc';
export var suggestionsList = '_161y1wda';