// ACQS-259: Check if HTMLDialogElement is supported by the browser to prevent Google Maps errors
import { useEffect, useState } from "react"

export const useHTMLDialogElementSupported = () => {
  const [isHTMLDialogElementSupported, setIsHTMLDialogElementSupported] = useState(true)

  useEffect(() => {
    if (typeof HTMLDialogElement !== "function") {
      setIsHTMLDialogElementSupported(false)
    }
  }, [])

  return { isHTMLDialogElementSupported }
}
