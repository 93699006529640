import { useRef } from "react"

const useSearchWhenDoneTyping = () => {
  const timer = useRef<ReturnType<typeof setTimeout>>()

  const useSearchWhenDoneTyping = (callback: () => void | Promise<void>, delay: number = 500) => {
    if (typeof timer.current !== "undefined") {
      clearTimeout(timer.current)
    }

    timer.current = setTimeout(callback, delay)
  }

  return useSearchWhenDoneTyping
}

export default useSearchWhenDoneTyping
